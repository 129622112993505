document.addEventListener("DOMContentLoaded", () => {
  // smooth-scrolling
  const elements = document.querySelectorAll(".js-scrollTo");
  Array.prototype.forEach.call(elements, function (el, i) {
    el.addEventListener("click", function (event) {
      const section = "#" + el.getAttribute("href").split("#")[1];

      const scrollToPosition =
        window.scrollY +
        document.querySelectorAll(section)[0].getBoundingClientRect().top;

      /*
       * alt:
       * https://developer.mozilla.org/fr/docs/Web/API/Element/scrollTo
       * https://developer.mozilla.org/fr/docs/Web/API/Element/scrollIntoView
       * scroll-behavior: smooth;
       */
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: scrollToPosition,
      });
      history.replaceState({}, "", section);
      event.preventDefault();
      return true;
    });
  });

  function checkScroll() {
    var startY = document
      .querySelectorAll(".navbar")[0]
      .getBoundingClientRect().height;

    if (window.scrollY > startY) {
      document.querySelectorAll(".navbar")[0].classList.add("scrolled");
    } else {
      document.querySelectorAll(".navbar")[0].classList.remove("scrolled");
    }
  }

  if (document.querySelectorAll(".navbar").length > 0) {
    window.addEventListener("scroll", checkScroll);
    window.addEventListener("load", checkScroll);
    window.addEventListener("resize", checkScroll);
  }

  function handleClickOutside(e) {
    if (!document.getElementById("language-menu").contains(e.target)) {
      document.querySelector(".dropdown").classList.toggle("is-active");
      window.removeEventListener("click", handleClickOutside);
    }
  }

  document.querySelector("#language-button").addEventListener("click", (e) => {
    const dropdown = document.querySelector(".dropdown");
    if (dropdown.classList.contains("is-active")) {
      window.removeEventListener("click", handleClickOutside);
    } else {
      window.addEventListener("click", handleClickOutside);
    }
    dropdown.classList.toggle("is-active");
    e.stopPropagation();
  });
});
